//import './App.css';
import PublicRoutes from './routes/public.routes';


function App() {
  return (
    
    <div className="App">
      <header className="App-header">
        <PublicRoutes/>
       
      </header>
    </div>
  );
}

export default App;