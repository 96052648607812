import React from "react";



const Excursiones = () => {

    return (
    <div>
        <div className="turismo">
        {/* <img src={turismo} alt= " "/> */}
        </div>
        
    </div>
    

    )
}

export default Excursiones;