import React from 'react';
import Contacto from '../components/Contacto/Contacto';
import Barrainferior from '../components/Barrainferior/Barrainferior';
import Barrasuperior from '../components/Barrasuperior/Barrasuperior';

const ActividadesViews = (props) => {
    return (
      <div>
        <Barrasuperior />
          <dr/>
          <br/>
          <br/>
          <br/>
          <Contacto />
          <br/>
          <dr/>
        <Barrainferior />
  
      </div>
    );
  }
  
  export default ActividadesViews;